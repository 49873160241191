<template>
  <div
    class="process-safety-management px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent
      title="Process Safety Management (PSM) Principles for Explosive Operations"
      size="large"
    />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <img
          src="@/assets/RiskManagement/Arch-Smooth-min.png"
          width="1381"
          height="870"
          alt=""
          title="PSM Elements Arch_yellow(no trade secret block)"
          class="img-responsive wp-image-12736"
        />
        <div class="grid grid-rows-2 grid-flow-col">
          <div>
            <a
              class="heading-link mb-4"
              style="float:left;"
              href="https://firebasestorage.googleapis.com/v0/b/smsenergetics.appspot.com/o/2023%20PSM%20PHA%20Flyer.pdf?alt=media&token=https://firebasestorage.googleapis.com/v0/b/smsenerge
              tics.appspot.com/o/2023%20PSM%20PHA%20Flyer.pdf?alt=media&token=87ce34ec-2a79-4171-81d8-bbe13f33aacb"
              target="_blank"
            >
              <div class="h-link">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="book"
                  class="svg-inline--fa fa-book fa-w-14"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M448 360V24c0-13.3-10.7-24-24-24H96C43 0 0 43 0 96v320c0 53 43 96 96 96h328c13.3 0 24-10.7 24-24v-16c0-7.5-3.5-14.3-8.9-18.7-4.2-15.4-4.2-59.3 0-74.7 5.4-4.3 8.9-11.1 8.9-18.6zM128 134c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm0 64c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm253.4 250H96c-17.7 0-32-14.3-32-32 0-17.6 14.4-32 32-32h285.4c-1.9 17.1-1.9 46.9 0 64z"
                  ></path>
                </svg>
                <h2
                  class="content-box-heading fusion-responsive-typography-calculated"
                  style="display:inline; font-size: 18px; line-height: 1.28; --fontSize:18; --minFontSize:18;"
                  data-fontsize="18"
                  data-lineheight="23.04px"
                >
                  PSM Training Information Flyer
                </h2>
              </div></a
            >
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            This course addresses the unique aspects of the propellant,
            explosives, and pyrotechnic (PEP) materials as they relate to OSHA
            29 CFR 1910.119 “Process Safety Management.” The course begins with
            a review of the heritage of the PEP industry and the fundamental
            principles of explosive safety. These principles are emphasized
            throughout the presentation and discussion of the elements of the
            PSM regulation. This course will provide managers, engineers, safety
            professionals, and others involved PSM compliance efforts with an
            understanding of the practices and procedures that should be
            implemented to provide safe operations and compliance with 29 CFR
            1910.119. Emphasis will be given to the benefits of implementing
            explosives safety protocol in conjunction with a PSM system (e.g.,
            improved safety, enhanced product quality, cost avoidance, etc.)
          </p>
          <p>Course Content:</p>
          <ul>
            <li>Explosives Manufacturing Heritage</li>
            <li>PSM Background</li>
            <li>
              Brief overview of inter-relationships of OSHA PSM, EPA RMP, ISO
              9000, &amp; ISO 14000
            </li>
            <li>PSM elements (with explosives safety perspective)</li>
            <li>Employee Participation</li>
            <li>Process Safety Information</li>
            <li>Process Hazard Analysis</li>
            <li>Management of Change &amp; “Replacement in Kind”</li>
            <li>Mechanical Integrity</li>
            <li>Operating procedures</li>
            <li>Training</li>
            <li>Contractors</li>
            <li>Hot Work Permit</li>
            <li>Incident Investigation</li>
            <li>Emergency Planning and Response</li>
            <li>PSM Audits</li>
            <li>Pre-startup Safety Reviews</li>
            <li>Documentation Requirements for PSM</li>
            <li>OSHA PSM Interpretations</li>
          </ul>
        </Paragraph>
        <div class="flex flex-wrap mt-4">
          <div class="w-1/2 pt-4">
            <a
              href="http://www.tci-training.com"
              target="_blank"
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Registration
            </a>
          </div>
          <div class="w-1/2">
            <a href="http://www.tci-training.com"
              ><img
                class="alignnone size-full wp-image-12060"
                src="@/assets/Training/tci-logo-min.jpg"
                alt="tci-logo"
                width="147"
                height="50"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Process Safety Management",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "This training course addresses all PEP materials as they relate to OSHA 29 CFR 1910.119 “Process Safety Management.”. Training includes PEP industry history, presentation on PSM regulations, training on practices and procedures to provide operations in compliance with 20 CFR 1910.119."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.process-safety-management {
  .h-link:hover {
    color: #aa222c;
    .fa-book {
      color: #aa222c;
    }
  }
  .fa-book {
    color: black;
    width: 18px;
    display: inline;
  }
}
</style>
